<template>
  <b-col class="right-sidebar d-none d-lg-block sticky-column col-xl-2">
    <div class="wrapper">
    <b-row align-h="between" align-v="center" class="mt-2">
      <b-col cols="auto">
        <span class="text-gray-3 text-lg">{{
          $t("page.facebook.contacts")
        }}</span>
      </b-col>
      <b-col
        ><img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+LUNEtwAAAfFJREFUaIHtmLtOwzAUhr8C4g0QK0IgmBBLgRlG2DqwkQVEBgaIqrIwIgQVCgMdMrCEjYEN3oE2G2vLZecNkLgMTVBx0yRuDFjFnxQpjs9Jzh/7nDgGg8FgMBgMg0Ohs7GxvRedVoASMA+M/lIsAXAFnPme+57VqVBoSxiK6asDJ8ACvycCoAicAneW7cTFlYjoUKEt4C8pAnupVgKikJKaWHKzLuswIrT/ejQiikmdlu2sArPAJfAC3UK0xrKdOeAMWA4vrRO+fGVCDso7TE9NfrWbrUcOT2tK7m3ZzhhwBGwKXV8jp0xIp4i4dj9YtjMK7ALHCJ8KEW2nVpgHNWAC+Eiz11YIcCNjLP3h0ZWBESI9tcTqlITvuV3XVFazTqRHJG81UlHN4hiYqfV/hTRbj7ke2Hx4yuXfC+lk75WocYlt2Y58RH3yf6eWrugsZA14zmqsrRDfc2+BGWA/i70yIWI1U1GdfM999T23CowDF0m2yla/P7HsiPA99wXYsmznnO9/iEFko/Myvgvfc++BFeGfHejeoKujxwZE4Htupjh6bdBdq46oT65kHUQhVaChJpa+CWjngRRxVWuRdslrAK85g5IhAMrAkszeb0SvZK+GRxrDEs96SzOIW69lJW/VioJLEpQqwGAwGAwGgyI+AUYqZ+3VmcGjAAAAAElFTkSuQmCC"
          alt=""
          width="19"
      /></b-col>
      <b-col>
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDAgNzkuMTYwNDUxLCAyMDE3LzA1LzA2LTAxOjA4OjIxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+LUNEtwAAApVJREFUWIXFlz9oE3EUxz8nnSSGbhEJOBTM4KiDg6BYHCIROziIguRsa08I6A3i4FC6i0e3E5VzKYhTg8UMYjA1m1a6FEwhk0HMIkUcQ3H4/c48Lrl/aRofHFy+v3fvffJ+v8t7gf9sBkB56UGUTx6YA4pAAchpvQu0gBqw7rlOJ3Vyw4gEyAPLQBmYionVA14BK2lADMPgSMhaCdgBFhIkR/ssADumZZeSAgBDASpAFcgKrQ7cRW3BMX0VtFYXflmgalp2ZVSAErAq9DZwEZgFngO7wB997WptVvu0RczVpJWQAHlgTWhN4CzQiAviuU5D+zZF3DXTsvNpAJbpl70NXAX24gIIiD39jF+JrI6ZCCCPOu2+zadJHoCYF1I5rgo+wBz9014nQdkjIBr0D+aUjh0LUBTa61GTh8QohnoJgILQRv72ITEKoV4CICe0H2MAkDFyoV4M/yGaqPkAXaGdGENcGaMb6iUAWkK7MAYAGaMV6iUAakK7MQaA2+K+FuolANZRLRXgEgeogmnZd4Dz+uO+jh0L0EH1c99eAtMjJJ9GNTPftuPmA/kWrAC/9f0M8DYNhE5eAzJCfhz3nAToALdQZQNVxi8k2A5d9u/AucDStzQAABvAfQExA3wEPgCLwCnUN8zo+0XgE2rLMgzaVlwzCpsJS6jZIBtcGMF+AiXPdbYGkkfMhBvAaeAF/bcjyvaBr6jGcxL4JdaOA5umZV8b9uChjOW67J91cgn50HOdp/+Sx4zlic1znQHNtOwzwCZwNLD0DKh4rtOL2oIDm97zm/QPtG9LwDvTsrNwyN3Qc50q8GjI0mXURH347dhznSeowxy0KxMB0HYPeB/QticG4LlOD7gOvEH9qWkC5iRyx9pfeeGnNKFGDPoAAAAASUVORK5CYII="
          alt=""
          width="19"
        />
      </b-col>
      <b-col>
        <span class="text-gray-3">
          <svg
            class=""
            viewBox="0 0 24 24"
            width="22"
            height="22"
            stroke="currentColor"
            stroke-width="2"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="1"></circle>
            <circle cx="19" cy="12" r="1"></circle>
            <circle cx="5" cy="12" r="1"></circle>
          </svg>
        </span>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col>
        <span class="text-gray-3 text-lg">{{
          $t("page.facebook.groupConversations")
        }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="d-flex align-items-center hoverable-item btn-height rounded-lg mt-2 mr-2"
      >
        <div>
          <span class="rounded-circle bg-gray-1 btn-svg mr-2">
            <svg
              viewBox="0 0 24 24"
              width="22"
              height="22"
              stroke="currentColor"
              stroke-width="2.5"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </span>
        </div>
        <div class="d-flex align-items-center">
          <span> {{ $t("page.facebook.createGroup") }} </span>
        </div>
      </b-col>
    </b-row>
  </div>
  </b-col>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "../../assets/facebook-styles.scss";

</style>
