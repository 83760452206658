<template>
  <div class="h-100">
    <SocialsPrompt
      v-if="showPrompt"
      :surveyStep="surveyStep"
      :surveyStepDuration="surveyStepDuration"
      @continue="continueSurvey"
    />
    <SocialsSpinner v-if="showSpinner" />
    <transition :name="'fade'">
      <div v-if="!showPrompt && !showSpinner">
        <Header />
        <div class="body text-md">
          <b-container fluid>
            <b-row class="justify-content-center justify-content-xl-center">
              <LeftSidebar />
              <PostsList />
              <RightSidebar />
            </b-row>
          </b-container>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from "../components/Facebook/Header.vue";
import LeftSidebar from "../components/Facebook/LeftSidebar.vue";
import PostsList from "../components/Facebook/PostsList.vue";
import RightSidebar from "../components/Facebook/RightSidebar.vue";

import SocialsPrompt from "../components/SocialsPrompt.vue";
import SocialsSpinner from "../components/SocialsSpinner.vue";

import {
  viewComponent,
  onNavigationBackToSetup,
} from "../mixins/instagramFacebookViewers";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Facebook",
  emits: ["finishedRecording"],
  components: {
    Header,
    LeftSidebar,
    PostsList,
    RightSidebar,
    SocialsPrompt,
    SocialsSpinner,
  },
  mixins: [viewComponent, onNavigationBackToSetup],

  computed: {
    ...mapGetters("facebook", {
      showPrompt: "getShowPrompt",
      surveyStep: "getSurveyStep",
      surveyStepDuration: "getSurveyStepDuration",
    }),
  },
  methods: {
    ...mapActions("facebook", ["getPosts"]),
    ...mapMutations("facebook", ["RESET_STATE", "SET_SHOW_PROMPT"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/facebook-styles.scss";

.body {
  @media screen and (max-width: 767px) {
    background-color: $gray-color-2;
    margin-top: $header-mobile-height;
  }
  @media screen and (min-width: 768px) {
    background-color: $white-color-2;
    margin-top: $header-desktop-height;
  }
}
</style>
